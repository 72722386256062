import {
  ITableColumn,
  ITaxEquitySummaryItem,
  ISponsorEquitySummaryItem,
  IDeveloperCashReport,
  IDeveloperTaxReport,
  ISponsorEquityBOL_CashReport,
  ISponsorEquityBOL_TaxReport,
  IDeveloperReportSummary,
  SponsorEquityBOL_ReportSummaryMetricsForView,
  IConstructionDebtLoanReport,
  IConstructionDebtCashReport,
  IConstructionDebtEquityReport,
  IConstructionDebtSummary,
  IReport,
} from "../interfaces";
import { enumToOptions } from "../utils/helpers";
import { CARRYFORWARD_CREDITS } from "./deal";
import { CONSTRUCTION_DEBT_BASE_RATE_TYPE } from "./deal/construction-debt";

export const PARTNERSHIP_SUMMARY_COLUMN: ITableColumn[] = [
  { id: "item", label: "Item", minWidth: 100, align: "left" },
  { id: "total", label: "Total", minWidth: 100, align: "right" },
  { id: "npv", label: "NPV", minWidth: 100, align: "right" },
  { id: "npv_percent", label: "NPV %", minWidth: 100, align: "right" },
];

export const SHORT_PARTNERSHIP_SUMMARY_COLUMN: ITableColumn[] = [
  { id: "item", label: "Item", minWidth: 100, align: "left" },
  { id: "total", label: "Result", minWidth: 100, align: "right" },
];

export const IRR_PARTNERSHIP_SUMMARY_COLUMN: ITableColumn[] = [
  { id: "item", label: "Item", minWidth: 100, align: "left" },
  { id: "total", label: "Result", minWidth: 100, align: "right" },
];

export const TAX_EQUITY_REPORT_COLUMN: ITableColumn[] = [
  { id: "items", label: "Item", minWidth: 100, align: "right" },
  { id: "totals", label: "Stakeholder Amount", minWidth: 100, align: "right" },
  {
    id: "total_percent",
    label: "Stakeholder Share",
    minWidth: 100,
    align: "right",
  },
  { id: "npv", label: "NPV", minWidth: 100, align: "right" },
  { id: "npv_percent", label: "NPV %", minWidth: 100, align: "right" },
];

export const CASH_REPORT_TABLE_COLUMNS_PARTNERSHIP_FOR_TRANSFER: ITableColumn[] =
  [
    { id: "dates", label: "Dates", minWidth: 150, align: "left" },
    {
      id: "deal_fmv",
      label: "Deal FMV",
      minWidth: 150,
      align: "right",
    },
    {
      id: "deal_fees",
      label: "Deal Fees",
      minWidth: 150,
      align: "right",
    },
    {
      id: "equity",
      label: "Capital Contributions",
      minWidth: 150,
      align: "right",
    },
    {
      id: "distributions",
      label: "Cash Distributions",
      minWidth: 150,
      align: "right",
    },
    {
      id: "transfer_proceeds",
      label: "Transfer Cash",
      minWidth: 150,
      align: "right",
    },
    {
      id: "pre_tax_cash",
      label: "Pre-Tax Cash",
      minWidth: 150,
      align: "right",
    },

    {
      id: "investment_tax_credits",
      label: "Tax Credits",
      minWidth: 150,
      align: "right",
    },

    {
      id: "pre_tax_cash_with_credit",
      label: "Pre-Tax Cash (with Credits)",
      minWidth: 150,
      align: "right",
    },
    {
      id: "cash_taxes_pre_credits",
      label: "Cash Taxes (without Credits)",
      minWidth: 150,
      align: "right",
    },

    {
      id: "after_tax_cash",
      label: "After-Tax Cash",
      minWidth: 150,
      align: "right",
    },
  ];

export const CASH_REPORT_TABLE_COLUMNS_PARTNERSHIP_FOR_DP: ITableColumn[] = [
  { id: "dates", label: "Dates", minWidth: 150, align: "left" },
  {
    id: "deal_fmv",
    label: "Deal FMV",
    minWidth: 150,
    align: "right",
  },
  {
    id: "deal_fees",
    label: "Deal Fees",
    minWidth: 150,
    align: "right",
  },
  {
    id: "equity",
    label: "Capital Contributions",
    minWidth: 150,
    align: "right",
  },
  {
    id: "distributions",
    label: "Cash Distributions",
    minWidth: 150,
    align: "right",
  },
  {
    id: "transfer_proceeds",
    label: "Direct Pay Cash",
    minWidth: 150,
    align: "right",
  },
  {
    id: "pre_tax_cash",
    label: "Pre-Tax Cash",
    minWidth: 150,
    align: "right",
  },

  {
    id: "investment_tax_credits",
    label: "Tax Credits",
    minWidth: 150,
    align: "right",
  },

  {
    id: "pre_tax_cash_with_credit",
    label: "Pre-Tax Cash (with Credits)",
    minWidth: 150,
    align: "right",
  },
  {
    id: "cash_taxes_pre_credits",
    label: "Cash Taxes (without Credits)",
    minWidth: 150,
    align: "right",
  },

  {
    id: "after_tax_cash",
    label: "After-Tax Cash",
    minWidth: 150,
    align: "right",
  },
];

export const CASH_REPORT_TABLE_COLUMNS_WO_TRANSFER_PROCEEDS_PARTNERSHIP: ITableColumn[] =
  [
    { id: "dates", label: "Dates", minWidth: 150, align: "left" },
    {
      id: "deal_fmv",
      label: "Deal FMV",
      minWidth: 150,
      align: "right",
    },
    {
      id: "deal_fees",
      label: "Deal Fees",
      minWidth: 150,
      align: "right",
    },
    {
      id: "equity",
      label: "Capital Contributions",
      minWidth: 150,
      align: "right",
    },
    {
      id: "distributions",
      label: "Cash Distributions",
      minWidth: 150,
      align: "right",
    },
    {
      id: "pre_tax_cash",
      label: "Pre-Tax Cash",
      minWidth: 150,
      align: "right",
    },

    {
      id: "investment_tax_credits",
      label: "Tax Credits",
      minWidth: 150,
      align: "right",
    },

    {
      id: "pre_tax_cash_with_credit",
      label: "Pre-Tax Cash (with Credits)",
      minWidth: 150,
      align: "right",
    },
    {
      id: "cash_taxes_pre_credits",
      label: "Cash Taxes (without Credits)",
      minWidth: 150,
      align: "right",
    },

    {
      id: "after_tax_cash",
      label: "After-Tax Cash",
      minWidth: 150,
      align: "right",
    },
  ];

export const getCashReportTableColumns = (
  report: IReport,
  buyout: string,
): ITableColumn[] => {
  const r = report["pship/cashdetail"];
  const minWidth = 150;

  const transferProceedsColumn = {
    id: "transfer_proceeds",
    label: "Transfer Cash",
    minWidth,
    align: "right",
  } as const;

  const preferredReturnColumn = {
    id: "distributions_investor_preferred_return",
    label: "Preferred Return",
    minWidth,
    align: "right",
  } as const;

  const distributionsSponsorCapitalReturn = {
    id: "distributions_sponsor_return_of_capital",
    label: "Return of Capital",
    minWidth,
    align: "right",
  } as const;

  const equityBuyoutColumn = {
    id: "equity_buyout",
    label: "Buyout Cash",
    minWidth,
    align: "right",
  } as const;

  const commonColumns = [
    {
      id: "deal_fmv",
      label: "Deal FMV",
      minWidth,
      align: "right",
    },
    {
      id: "deal_fees",
      label: "Deal Fees",
      minWidth,
      align: "right",
    },
    {
      id: "equity",
      label: "Capital Contributions",
      minWidth,
      align: "right",
    },
    ...(r.distributions_investor_preferred_return !== undefined
      ? [preferredReturnColumn]
      : []),
    ...(r.distributions_sponsor_return_of_capital !== undefined
      ? [distributionsSponsorCapitalReturn]
      : []),
    {
      id: "distributions",
      label: "Cash Distributions",
      minWidth,
      align: "right",
    },
    ...(r.transfer_proceeds !== undefined ? [transferProceedsColumn] : []),
    ...(r.equity_buyout !== undefined ? [equityBuyoutColumn] : []),
  ] as const;

  return [
    { id: "dates", label: "Dates", minWidth, align: "left" },
    ...(buyout !== "taxflip" ? commonColumns : []),
    {
      id: "pre_tax_cash",
      label: "Pre-Tax Cash",
      minWidth,
      align: "right",
    },

    {
      id: "investment_tax_credits",
      label: "Tax Credits",
      minWidth,
      align: "right",
    },

    {
      id: "pre_tax_cash_with_credit",
      label: "Pre-Tax Cash (with Credits)",
      minWidth,
      align: "right",
    },
    {
      id: "cash_taxes_pre_credits",
      label: "Cash Taxes (without Credits)",
      minWidth,
      align: "right",
    },

    {
      id: "after_tax_cash",
      label: "After-Tax Cash",
      minWidth,
      align: "right",
    },
  ];
};

export const CASH_REPORT_TABLE_COLUMNS_TAX_EQUITY: ITableColumn[] = [
  { id: "dates", label: "Dates", minWidth: 100, align: "right" },
  {
    id: "equity",
    label: "Net Equity",
    minWidth: 150,
    align: "right",
  },
  {
    id: "distributions_investor_preferred_return",
    label: "Preferred Return",
    minWidth: 150,
    align: "right",
  },
  {
    id: "distributions",
    label: "Cash Distributions",
    minWidth: 180,
    align: "right",
  },
  {
    id: "transfer_proceeds",
    label: "Transfer Cash",
    minWidth: 180,
    align: "right",
  },
  {
    id: "pre_tax_cash",
    label: "Pre-Tax Cash",
    minWidth: 150,
    align: "right",
  },

  {
    id: "investment_tax_credits",
    label: "Tax Credits",
    minWidth: 150,
    align: "right",
  },

  {
    id: "pre_tax_cash_with_credit",
    label: "Pre-Tax Cash (with Credits)",
    minWidth: 250,
    align: "right",
  },
  {
    id: "cash_taxes_pre_credits",
    label: "Cash Taxes (without Credits)",
    minWidth: 250,
    align: "right",
  },

  {
    id: "after_tax_cash",
    label: "After-Tax Cash",
    minWidth: 200,
    align: "right",
  },
];

export const CASH_REPORT_TABLE_COLUMNS_WO_TRANSFER_PROCEEDS_TAX_EQUITY: ITableColumn[] =
  [
    { id: "dates", label: "Dates", minWidth: 80, align: "right" },
    {
      id: "equity",
      label: "Net Equity",
      minWidth: 180,
      align: "right",
    },
    {
      id: "distributions_investor_preferred_return",
      label: "Preferred Return",
      minWidth: 150,
      align: "right",
    },
    {
      id: "distributions",
      label: "Cash Distributions",
      minWidth: 220,
      align: "right",
    },
    {
      id: "pre_tax_cash",
      label: "Pre-Tax Cash",
      minWidth: 180,
      align: "right",
    },

    {
      id: "investment_tax_credits",
      label: "Tax Credits",
      minWidth: 180,
      align: "right",
    },

    {
      id: "pre_tax_cash_with_credit",
      label: "Pre-Tax Cash (with Credits)",
      minWidth: 250,
      align: "right",
    },
    {
      id: "cash_taxes_pre_credits",
      label: "Cash Taxes (without Credits)",
      minWidth: 250,
      align: "right",
    },

    {
      id: "after_tax_cash",
      label: "After-Tax Cash",
      minWidth: 180,
      align: "right",
    },
  ];

export const CASH_REPORT_TABLE_COLUMNS_DEBT: ITableColumn[] = [
  { id: "dates", label: "Dates", minWidth: 100, align: "right" },
  {
    id: "equity",
    label: "Net Equity",
    minWidth: 100,
    align: "right",
  },
  {
    id: "distributions",
    label: "Cash Distributions",
    minWidth: 100,
    align: "right",
  },
  {
    id: "transfer_proceeds",
    label: "Transfer Cash",
    minWidth: 100,
    align: "right",
  },
  {
    id: "pre_tax_cash",
    label: "Pre-Tax Cash",
    minWidth: 100,
    align: "right",
  },

  {
    id: "investment_tax_credits",
    label: "Tax Credits",
    minWidth: 100,
    align: "right",
  },

  {
    id: "pre_tax_cash_with_credit",
    label: "Pre-Tax Cash (with Credits)",
    minWidth: 100,
    align: "right",
  },

  {
    id: "after_tax_cash",
    label: "After-Tax Cash",
    minWidth: 100,
    align: "right",
  },
];

export const CASH_REPORT_TABLE_COLUMNS_SPONSOR_EQUITY_FOR_TRANSFER: ITableColumn[] =
  [
    { id: "dates", label: "Dates", minWidth: 80, align: "right" },
    {
      id: "capital_contributions",
      label: "Capital Contributions",
      minWidth: 200,
      align: "right",
    },
    {
      id: "fmv_step_up",
      label: "FMV Step Up",
      minWidth: 150,
      align: "right",
    },
    {
      id: "debt_proceeds",
      label: "Debt Proceeds",
      minWidth: 150,
      align: "right",
    },
    {
      id: "equity_buyout",
      label: "Equity Buyout",
      minWidth: 180,
      align: "right",
    },
    {
      id: "equity",
      label: "Net Equity",
      minWidth: 150,
      align: "right",
    },
    {
      id: "distributions_sponsor_return_of_capital",
      label: "Return of Capital",
      minWidth: 150,
      align: "right",
    },
    {
      id: "distributions",
      label: "Cash Distributions",
      minWidth: 180,
      align: "right",
    },
    {
      id: "transfer_proceeds",
      label: "Transfer Cash",
      minWidth: 180,
      align: "right",
    },
    {
      id: "distributions_sponsor_bl_ds",
      label: "Back-Leverage Debt Service",
      minWidth: 240,
      align: "right",
    },
    {
      id: "cash_released_reserved",
      label: "Cash Released/(Reserved)",
      minWidth: 240,
      align: "right",
    },
    {
      id: "pre_tax_cash",
      label: "Pre-Tax Cash",
      minWidth: 200,
      align: "right",
    },

    {
      id: "investment_tax_credits",
      label: "Tax Credits",
      minWidth: 150,
      align: "right",
    },

    {
      id: "pre_tax_cash_with_credit",
      label: "Pre-Tax Cash (with Credits)",
      minWidth: 250,
      align: "right",
    },

    {
      id: "cash_taxes_pre_credits",
      label: "Cash Taxes (without Credits)",
      minWidth: 250,
      align: "right",
    },

    {
      id: "after_tax_cash",
      label: "After-Tax Cash",
      minWidth: 150,
      align: "right",
    },
  ];

export const CASH_REPORT_TABLE_COLUMNS_SPONSOR_EQUITY_FOR_DP: ITableColumn[] = [
  { id: "dates", label: "Dates", minWidth: 80, align: "right" },
  {
    id: "capital_contributions",
    label: "Capital Contributions",
    minWidth: 200,
    align: "right",
  },
  {
    id: "fmv_step_up",
    label: "FMV Step Up",
    minWidth: 150,
    align: "right",
  },
  {
    id: "debt_proceeds",
    label: "Debt Proceeds",
    minWidth: 150,
    align: "right",
  },
  {
    id: "equity_buyout",
    label: "Equity Buyout",
    minWidth: 180,
    align: "right",
  },
  {
    id: "equity",
    label: "Net Equity",
    minWidth: 150,
    align: "right",
  },
  {
    id: "distributions_sponsor_return_of_capital",
    label: "Return of Capital",
    minWidth: 150,
    align: "right",
  },
  {
    id: "distributions",
    label: "Cash Distributions",
    minWidth: 180,
    align: "right",
  },
  {
    id: "transfer_proceeds",
    label: "Direct Pay Cash",
    minWidth: 180,
    align: "right",
  },
  {
    id: "distributions_sponsor_bl_ds",
    label: "Back-Leverage Debt Service",
    minWidth: 240,
    align: "right",
  },
  {
    id: "cash_released_reserved",
    label: "Cash Released/(Reserved)",
    minWidth: 240,
    align: "right",
  },
  {
    id: "pre_tax_cash",
    label: "Pre-Tax Cash",
    minWidth: 200,
    align: "right",
  },

  {
    id: "investment_tax_credits",
    label: "Tax Credits",
    minWidth: 150,
    align: "right",
  },

  {
    id: "pre_tax_cash_with_credit",
    label: "Pre-Tax Cash (with Credits)",
    minWidth: 250,
    align: "right",
  },

  {
    id: "cash_taxes_pre_credits",
    label: "Cash Taxes (without Credits)",
    minWidth: 250,
    align: "right",
  },

  {
    id: "after_tax_cash",
    label: "After-Tax Cash",
    minWidth: 150,
    align: "right",
  },
];

// When transfer proceeeds are there
export const CASH_REPORT_TABLE_COLUMNS_WO_TRANSFER_PROCEEDS_SPONSOR_EQUITY: ITableColumn[] =
  [
    { id: "dates", label: "Dates", minWidth: 80, align: "right" },
    {
      id: "capital_contributions",
      label: "Capital Contributions",
      minWidth: 200,
      align: "right",
    },
    {
      id: "fmv_step_up",
      label: "FMV Step Up",
      minWidth: 180,
      align: "right",
    },
    {
      id: "debt_proceeds",
      label: "Debt Proceeds",
      minWidth: 180,
      align: "right",
    },
    {
      id: "equity_buyout",
      label: "Equity Buyout",
      minWidth: 180,
      align: "right",
    },
    {
      id: "equity",
      label: "Net Equity",
      minWidth: 150,
      align: "right",
    },
    {
      id: "distributions_sponsor_return_of_capital",
      label: "Return of Capital",
      minWidth: 150,
      align: "right",
    },
    {
      id: "distributions",
      label: "Cash Distributions",
      minWidth: 180,
      align: "right",
    },
    {
      id: "distributions_sponsor_bl_ds",
      label: "Back-Leverage Debt Service",
      minWidth: 250,
      align: "right",
    },
    {
      id: "cash_released_reserved",
      label: "Cash Released/(Reserved)",
      minWidth: 240,
      align: "right",
    },
    {
      id: "pre_tax_cash",
      label: "Pre-Tax Cash",
      minWidth: 150,
      align: "right",
    },

    {
      id: "investment_tax_credits",
      label: "Tax Credits",
      minWidth: 150,
      align: "right",
    },

    {
      id: "pre_tax_cash_with_credit",
      label: "Pre-Tax Cash (with Credits)",
      minWidth: 250,
      align: "right",
    },

    {
      id: "cash_taxes_pre_credits",
      label: "Cash Taxes (without Credits)",
      minWidth: 250,
      align: "right",
    },

    {
      id: "after_tax_cash",
      label: "After-Tax Cash",
      minWidth: 180,
      align: "right",
    },
  ];

export const getCashSponsorReportTableColumns = (
  report: IReport,
  tax_credit_structure: "DP" | "REG" | "TR" | undefined,
): ITableColumn[] => {
  const minWidth = 150;
  const r = report["pship/cashdetail"];
  const transferProceedsColumn = {
    id: "transfer_proceeds",
    label: tax_credit_structure == "DP" ? "Direct Pay Cash" : "Transfer Cash",
    minWidth,
    align: "right",
  } as const;

  const distributionsSponsorCapitalReturn = {
    id: "distributions_sponsor_return_of_capital",
    label: "Return of Capital",
    minWidth: 150,
    align: "right",
  } as const;

  const buyoutEquityColumn = {
    id: "equity_buyout",
    label: "Buyout Payment",
    minWidth,
    align: "right",
  } as const;

  return [
    { id: "dates", label: "Dates", minWidth, align: "left" },
    {
      id: "deal_fmv",
      label: "Share of Deal FMV",
      minWidth,
      align: "right",
    },
    {
      id: "deal_fees",
      label: "Share of Deal Fees",
      minWidth,
      align: "right",
    },
    {
      id: "capital_contributions",
      label: "Capital Contributions",
      minWidth,
      align: "right",
    },
    {
      id: "fmv_step_up",
      label: "FMV Step Up",
      minWidth,
      align: "right",
    },
    {
      id: "debt_proceeds",
      label: "Term Debt Proceeds",
      minWidth,
      align: "right",
    },
    {
      id: "equity",
      label: "Net Equity",
      minWidth,
      align: "right",
    },
    ...(r.distributions_sponsor_return_of_capital !== undefined
      ? [distributionsSponsorCapitalReturn]
      : []),
    {
      id: "distributions",
      label: "Cash Distributions",
      minWidth,
      align: "right",
    },
    ...(r.transfer_proceeds !== undefined ? [transferProceedsColumn] : []),
    ...(r.equity_buyout !== undefined ? [buyoutEquityColumn] : []),
    {
      id: "distributions_sponsor_bl_ds",
      label: "Term Debt Service",
      minWidth,
      align: "right",
    },
    {
      id: "cash_released_reserved",
      label: "Cash Released/(Reserved)",
      minWidth,
      align: "right",
    },
    {
      id: "pre_tax_cash",
      label: "Pre-Tax Cash",
      minWidth,
      align: "right",
    },

    {
      id: "investment_tax_credits",
      label: "Tax Credits",
      minWidth,
      align: "right",
    },

    {
      id: "pre_tax_cash_with_credit",
      label: "Pre-Tax Cash (with Credits)",
      minWidth,
      align: "right",
    },

    {
      id: "cash_taxes_pre_credits",
      label: "Cash Taxes (without Credits)",
      minWidth,
      align: "right",
    },

    {
      id: "after_tax_cash",
      label: "After-Tax Cash",
      minWidth,
      align: "right",
    },
  ];
};

export const DEBT_FACILITY_REPORT_TABLE_COLUMNS: ITableColumn[] = [
  { id: "dates", label: "Date", minWidth: 150, align: "left" },
  {
    id: "beginning_balance",
    label: "Beginning Balance",
    minWidth: 150,
    align: "right",
  },
  {
    id: "draw_down",
    label: "Draw Down",
    minWidth: 150,
    align: "right",
  },
  // TODO: currently investment_tax_credits but its not confirmed
  { id: "interest", label: "Interest", minWidth: 150, align: "right" },
  {
    id: "principal",
    label: "Principal",
    minWidth: 150,
    align: "right",
  },
  {
    id: "balance",
    label: "Ending Balance",
    minWidth: 150,
    align: "right",
  },
  {
    id: "debt_service",
    label: "Debt Service",
    minWidth: 150,
    align: "right",
  },
];

export const TRANSFER_CASH_REPORT_TABLE_COLUMNS: ITableColumn[] = [
  { id: "dates", label: "Dates", minWidth: 150, align: "left" },
  {
    id: "credits_transferred",
    label: "Tax Credits",
    minWidth: 150,
    align: "right",
  },
  {
    id: "cash_paid_to_investor",
    label: "Payments to Investor",
    minWidth: 150,
    align: "right",
  },
  {
    id: "cash_paid_to_sponsor",
    label: "Payments to Sponsor Equity",
    minWidth: 150,
    align: "right",
  },
  {
    id: "after_tax_cash",
    label: "After-Tax Cash Flow",
    minWidth: 150,
    align: "right",
  },
  {
    id: "cumulative_atc",
    label: "Cumulative After-Tax Cash Flow",
    minWidth: 150,
    align: "right",
  },
];

export const TAX_REPORT_TABLE_COLUMNS: ITableColumn[] = [
  { id: "dates", label: "Dates", minWidth: 150, align: "left" },
  {
    id: "net_operating_income",
    label: "Net Operating Income",
    minWidth: 150,
    align: "right",
  },
  { id: "depreciation", label: "Depreciation", minWidth: 150, align: "right" },
  { id: "fmv_step_up", label: "FMV Step Up", minWidth: 150, align: "right" },
  {
    id: "other_income_interest_on_back_leverage",
    label: "Interest Deductions",
    minWidth: 150,
    align: "right",
  },
  {
    id: "other_income_loss",
    label: "Other Income/(Loss)",
    minWidth: 150,
    align: "right",
  },
  {
    id: "total_taxable_income",
    label: "Total Taxable Income",
    minWidth: 150,
    align: "right",
  },
  { id: "tax_rate", label: "Tax Rate", minWidth: 150, align: "right" },
  {
    id: "cash_taxes_before_credit",
    label: "Cash Taxes (without Credits)",
    minWidth: 150,
    align: "right",
  },
  { id: "credit", label: "Tax Credits", minWidth: 150, align: "right" },
  { id: "cash_taxes", label: "Cash Taxes", minWidth: 150, align: "right" },
  // { id: "taxable_revenue", label: "Revenues", minWidth: 100, align: "right" },
  // { id: "additional_capital_depreciation", label: "Additional Capital Depreciation", minWidth: 100, align: "right" },
  // { id: "reallocated_income", label: "Reallocated Income", minWidth: 100, align: "right" },
  // { id: "allocated_income", label: "Allocated Income", minWidth: 100, align: "right" },
];

export const TAX_REPORT_SHORT_TABLE_COLUMNS: ITableColumn[] = [
  { id: "dates", label: "Dates", minWidth: 150, align: "left" },
  {
    id: "net_operating_income",
    label: "Net Operating Income",
    minWidth: 150,
    align: "right",
  },
  { id: "depreciation", label: "Depreciation", minWidth: 150, align: "right" },
  {
    id: "other_income_loss",
    label: "Other Income/(Loss)",
    minWidth: 150,
    align: "right",
  },
  {
    id: "total_taxable_income",
    label: "Total Taxable Income",
    minWidth: 150,
    align: "right",
  },
  { id: "tax_rate", label: "Tax Rate", minWidth: 150, align: "right" },
  {
    id: "cash_taxes_before_credit",
    label: "Cash Taxes (without Credits)",
    minWidth: 150,
    align: "right",
  },
  { id: "credit", label: "Tax Credits", minWidth: 150, align: "right" },
  { id: "cash_taxes", label: "Cash Taxes", minWidth: 150, align: "right" },
  // { id: "taxable_revenue", label: "Revenues", minWidth: 100, align: "right" },
  // { id: "additional_capital_depreciation", label: "Additional Capital Depreciation", minWidth: 100, align: "right" },
  // { id: "reallocated_income", label: "Reallocated Income", minWidth: 100, align: "right" },
  // { id: "allocated_income", label: "Allocated Income", minWidth: 100, align: "right" },
];

export const PORTFOLIO_SUMMARY_TABLE_COLUMNS: ITableColumn[] = [
  { id: "item", label: "Item", minWidth: 100, align: "left" },
  { id: "partnership", label: "Partnership", minWidth: 100, align: "right" },
  { id: "tax_equity", label: "Tax Equity", minWidth: 100, align: "right" },
  {
    id: "sponsor_equity",
    label: "Sponsor Equity",
    minWidth: 100,
    align: "right",
  },
  // {
  //   id: "debt",
  //   label: "Debt",
  //   minWidth: 100,
  //   align: "right",
  // },
];

export const ACCOUNTING_REPORT_TABLE_COLUMNS: ITableColumn[] = [
  { id: "Date", label: "Date", minWidth: 150, align: "left" },
  { id: "Pre-Tax Cash", label: "Pre-Tax Cash", minWidth: 150, align: "right" },
  {
    id: "Beginning HLBV",
    label: "Beginning HLBV",
    minWidth: 150,
    align: "right",
  },
  { id: "Ending HLBV", label: "Ending HLBV", minWidth: 150, align: "right" },
  {
    id: "Change in HLBV",
    label: "Change in HLBV",
    minWidth: 150,
    align: "right",
  },
  {
    id: "Credit",
    label: "Credit",
    minWidth: 150,
    align: "right",
  },
  {
    id: "Deferred Tax Asset",
    label: "Deferred Tax Asset",
    minWidth: 150,
    align: "right",
  },
  {
    id: "Pre-Tax Book Income",
    label: "Pre-Tax Book Income",
    minWidth: 150,
    align: "right",
  },
  {
    id: "Suspended Book Losses",
    label: "Suspended Book Losses",
    minWidth: 150,
    align: "right",
  },
  {
    id: "Provision for Taxes",
    label: "Provision for Taxes",
    minWidth: 150,
    align: "right",
  },
  {
    id: "Tax Effect of Credit Transfer",
    label: "Tax Effect of Credit Transfer",
    minWidth: 150,
    align: "right",
  },
  {
    id: "Tax Effect of Basis Reduction",
    label: "Tax Effect of Basis Reduction",
    minWidth: 150,
    align: "right",
  },
  {
    id: "Allocated Tax Credit",
    label: "Allocated Tax Credit",
    minWidth: 150,
    align: "right",
  },
  {
    id: "Other Credits",
    label: "Other Credits",
    minWidth: 150,
    align: "right",
  },
  {
    id: "After-Tax Book Income",
    label: "After-Tax Book Income",
    minWidth: 150,
    align: "right",
  },
];

export const ACCOUNTING_PAM_REPORT_TABLE_COLUMNS: ITableColumn[] = [
  { id: "dates", label: "Dates", minWidth: 150, align: "left" },
  {
    id: "pre_tax_cash",
    label: "Pre-Tax Cash",
    minWidth: 150,
    align: "right",
  },
  {
    id: "taxable_income",
    label: "Taxable Income",
    minWidth: 150,
    align: "right",
  },
  {
    id: "tax_liability",
    label: "Tax Liability",
    minWidth: 150,
    align: "right",
  },
  {
    id: "taxable_benefit",
    label: "Taxable Benefit",
    minWidth: 150,
    align: "right",
  },
  {
    id: "tax_on_taxable_benefit",
    label: "Tax on Taxable Benefit",
    minWidth: 150,
    align: "right",
  },
  {
    id: "tax_credit",
    label: "Tax Credit",
    minWidth: 150,
    align: "right",
  },
  {
    id: "total_tax_benefit",
    label: "Total Tax Benefit",
    minWidth: 150,
    align: "right",
  },
  {
    id: "total_tax_benefit_proportion",
    label: "Total Tax Benefit Proportion",
    minWidth: 150,
    align: "right",
  },
  {
    id: "capital_payments",
    label: "Capital Payments",
    minWidth: 150,
    align: "right",
  },
  {
    id: "pre_tax_cash_applied_to_capital",
    label: "Pre-Tax Cash Applied to Capital",
    minWidth: 150,
    align: "right",
  },
  {
    id: "capital_basis",
    label: "Capital Basis",
    minWidth: 150,
    align: "right",
  },
  {
    id: "unadjusted_amortization_of_capital",
    label: "Unadjusted Amortization of Capital",
    minWidth: 150,
    align: "right",
  },
  {
    id: "adjusted_amortization_of_capital",
    label: "Adjusted Amortization of Capital",
    minWidth: 150,
    align: "right",
  },
  {
    id: "capital_amortization_balance",
    label: "Capital Amortization Balance",
    minWidth: 150,
    align: "right",
  },
  {
    id: "pre_tax_book_income",
    label: "Pre-Tax Book Income",
    minWidth: 150,
    align: "right",
  },
  {
    id: "amortization_of_capital",
    label: "Amortization of Capital",
    minWidth: 150,
    align: "right",
  },
  {
    id: "total_tax_provision",
    label: "Total Tax Provision",
    minWidth: 150,
    align: "right",
  },
  {
    id: "after_tax_book_income",
    label: "After-Tax Book Income",
    minWidth: 150,
    align: "right",
  },
];

export const CAPITAL_ACCOUNTS_REPORT_TABLE_COLUMNS: ITableColumn[] = [
  { id: "Date", label: "Date", minWidth: 150, align: "left" },
  {
    id: "Capital Contributions",
    label: "Capital Contributions",
    minWidth: 150,
    align: "right",
  },
  {
    id: "Total Distributions",
    label: "Total Distributions",
    minWidth: 150,
    align: "right",
  },
  {
    id: "Basis Reduction",
    label: "Basis Reduction",
    minWidth: 150,
    align: "right",
  },
  {
    id: "Allocated Income/(Loss)",
    label: "Allocated Income/(Loss)",
    minWidth: 150,
    align: "right",
  },
  {
    id: "Other Adjustments",
    label: "Other Adjustments",
    minWidth: 150,
    align: "right",
  },
  {
    id: "Capital Account Balance",
    label: "Capital Account Balance",
    minWidth: 150,
    align: "right",
  },
];

export const OUTSIDE_BASIS_REPORT_TABLE_COLUMNS: ITableColumn[] = [
  { id: "Date", label: "Date", minWidth: 150, align: "left" },
  {
    id: "Capital Contributions",
    label: "Capital Contributions",
    minWidth: 150,
    align: "right",
  },
  {
    id: "Total Distributions",
    label: "Total Distributions",
    minWidth: 150,
    align: "right",
  },
  {
    id: "Basis Reduction",
    label: "Basis Reduction",
    minWidth: 150,
    align: "right",
  },
  {
    id: "Allocated Income/(Loss)",
    label: "Allocated Income/(Loss)",
    minWidth: 150,
    align: "right",
  },
  {
    id: "Suspended Losses",
    label: "Suspended Losses",
    minWidth: 150,
    align: "right",
  },
  {
    id: "Excess Distributions",
    label: "Excess Distributions",
    minWidth: 150,
    align: "right",
  },
  { id: "Other", label: "Other", minWidth: 150, align: "right" },
  {
    id: "Partner Outside Basis",
    label: "Partner Outside Basis",
    minWidth: 150,
    align: "right",
  },
];

export const ITEMS_AND_TOTAL_TABLE_COLUMN: ITableColumn[] = [
  { id: "items", label: "Item", minWidth: 100, align: "left" },
  { id: "totals", label: "Result", minWidth: 100, align: "right" },
];

export const CONSTRUCTION_DEBT_LOANS_SUMMARY_TABLE_COLUMNS: ITableColumn[] = [
  { id: "loan_type", label: "Loan Type", minWidth: 100, align: "left" },
  {
    id: "construction_loan",
    label: "Construction Loan",
    minWidth: 100,
    align: "left",
  },
  {
    id: "tax_equity_bridge_loan",
    label: "Investor Bridge Loan",
    minWidth: 100,
    align: "left",
  },
  // {
  //   id: "transfer_bridge_loan",
  //   label: "Transfer Bridge Loan",
  //   minWidth: 100,
  //   align: "left",
  // },
];

export const CONSTRUCTION_DEBT_LOANS_SUMMARY_TABLE_ITEMS: ITableColumn<
  | keyof IConstructionDebtSummary["data"]["construction_loan"]
  | keyof IConstructionDebtSummary["data"]["tax_equity_bridge_loan"]
  // | keyof IConstructionDebtSummary["data"]["transfer_bridge_loan"]
  | "admin_agent_fee"
>[] = [
  {
    id: "advance_rate",
    label: "Advance Rate",
    minWidth: 100,
    align: "left",
    suffix: "%",
  },
  {
    id: "base_rate_type",
    label: "Base Rate Type",
    minWidth: 100,
    align: "left",
    enum: CONSTRUCTION_DEBT_BASE_RATE_TYPE,
  },
  {
    id: "base_rate",
    label: "Base Rate",
    minWidth: 100,
    align: "left",
    suffix: "%",
  },
  { id: "hedge", label: "Hedge %", minWidth: 100, align: "left", suffix: "%" },
  {
    id: "swap_rate",
    label: "Swap Rate",
    minWidth: 100,
    align: "left",
    suffix: "%",
  },
  {
    id: "swap_credit_spread",
    label: "Swap Credit Spread",
    minWidth: 100,
    align: "left",
    suffix: "%",
  },
  {
    id: "commitment_fee",
    label: "Commitment Fee",
    minWidth: 100,
    align: "left",
    suffix: "%",
  },
  {
    id: "upfront_fee",
    label: "Upfront Fee",
    minWidth: 100,
    align: "left",
    suffix: "%",
  },
];

export const DEVELOPER_CASH_REPORT_TABLE_COLUMNS: ITableColumn<
  keyof IDeveloperCashReport["data"]["items"]
>[] = [
  {
    id: "dates",
    label: "Date",
    minWidth: 150,
    align: "left",
  },
  {
    id: "project_cost",
    label: "Total Project Cost",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  // {
  //   id: "total_idc",
  //   label: "Interest During Construction",
  //   minWidth: 150,
  //   align: "right",
  //   formatNumbers: true,
  // },
  // {
  //   id: "total_commitment_fees",
  //   label: "Commitment Fees",
  //   minWidth: 150,
  //   align: "right",
  //   formatNumbers: true,
  // },
  // {
  //   id: "total_upfront_fees",
  //   label: "Upfront Fees",
  //   minWidth: 150,
  //   align: "right",
  //   formatNumbers: true,
  // },
  // {
  //   id: "total_lc_fees",
  //   label: "LC Fees",
  //   minWidth: 150,
  //   align: "right",
  //   formatNumbers: true,
  // },
  {
    id: "total_construction_interest_and_fees",
    label: "Construction Interest & Fees",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "deal_allocated_costs",
    label: "Deal Allocated Costs",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "total_project_costs_including_idc_and_fees",
    label: "Deal Project Cost",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "credit_support_cash_collateral",
    label: "Net Cash Collateral",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "construction_loan_drawdowns",
    label: "Construction Loan Draws",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "tax_equity_bridge_loan_drawdowns",
    label: "Investor Bridge Loan Draws",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  // {
  //   id: "transfer_bridge_loan_drawdowns",
  //   label: "Transfer Bridge Loan Draws",
  //   minWidth: 150,
  //   align: "right",
  //   formatNumbers: true,
  // },
  {
    id: "construction_loan_repayments",
    label: "Construction Loan Repayment",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "tax_equity_bridge_loan_repayments",
    label: "Investor Bridge Loan Repayment",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "investor_contributions",
    label: "Investor Contributions",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "term_debt_proceeds",
    label: "Term Debt Proceeds",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  // {
  //   id: "transfer_bridge_loan_repayments",
  //   label: "Transfer Bridge Loan Repayment",
  //   minWidth: 150,
  //   align: "right",
  //   formatNumbers: true,
  // },
  // {
  //   id: "credit_support_lc_fee",
  //   label: "Credit Support LC fee",
  //   minWidth: 180,
  //   align: "right",
  //   formatNumbers: true,
  // },
  {
    id: "equity_contributions",
    label: "Equity Contributions",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "developer_fee_payments",
    label: "Developer Fee Payments",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "development_cost_reimbursements",
    label: "Developer Cost Reimbursements",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  // {
  //   id: "developer_contributed_basis",
  //   label: "Developer Contributed Basis",
  //   minWidth: 180,
  //   align: "right",
  //   formatNumbers: true,
  // },
  // {
  //   id: "credit_support_reimbursements",
  //   label: "Credit Support Reimbursements",
  //   minWidth: 180,
  //   align: "right",
  //   formatNumbers: true,
  // },
  {
    id: "pre_tax_cash",
    label: "Pre-Tax Cash",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "cash_taxes",
    label: "Cash Taxes",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "after_tax_cash",
    label: "After Tax Cash",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
];

export const DEVELOPER_TAX_REPORT_TABLE_COLUMNS: ITableColumn<
  keyof IDeveloperTaxReport["data"]["items"]
>[] = [
  {
    id: "dates",
    label: "Date",
    minWidth: 150,
    align: "left",
  },
  {
    id: "developer_fee_payments",
    label: "Developer Fee Payments",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "tax_rate",
    label: "Tax Rate",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
    suffix: "%",
  },
  {
    id: "cash_taxes",
    label: "Cash Taxes",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
];

export const SPONSOR_EQUITY_BOL_CASH_REPORT_TABLE_COLUMNS: ITableColumn<
  keyof ISponsorEquityBOL_CashReport["data"]["items"]
>[] = [
  {
    id: "dates",
    label: "Date",
    minWidth: 150,
    align: "left",
  },
  {
    id: "total_project_costs_including_idc_and_fees",
    label: "Deal Project Cost",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "credit_support_cash_collateral",
    label: "Net Cash Collateral",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "construction_debt_proceeds",
    label: "Construction Debt Proceeds",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "construction_debt_repayments",
    label: "Construction Debt Repayment",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "term_debt_proceeds",
    label: "Term Debt Proceeds",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "investor_contributions",
    label: "Investor Contributions",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "deal_fees",
    label: "Deal Fees",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "net_equity",
    label: "Net Equity",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "return_of_capital",
    label: "Return of Capital",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "distributions",
    label: "Cash Distributions",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "distributions_sponsor_bl_ds",
    label: "Term Debt Service",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "cash_released_reserved",
    label: "Cash Released / (Reserved)",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "pre_tax_cash",
    label: "Pre-Tax Cash",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "investment_tax_credits",
    label: "Tax Credits",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "pre_tax_cash_with_credit",
    label: "Pre-Tax Cash (With Credit)",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "cash_taxes_pre_cod",
    label: "Cash Taxes (Pre-COD)",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "cash_taxes_before_credit",
    label: "Cash Taxes (without Credits)",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "after_tax_cash",
    label: "After Tax Cash",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
];

export const SPONSOR_EQUITY_BOL_CASH_REPORT_TABLE_TRANSFER_ADDITIONAL_COLUMNS: ITableColumn<
  keyof ISponsorEquityBOL_CashReport["data"]["items"]
>[] = [
  {
    id: "transfer_proceeds",
    label: "Transfer Cash",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
];

export const SPONSOR_EQUITY_BOL_CASH_REPORT_TABLE_DIRECT_PAY_ADDITIONAL_COLUMNS: ITableColumn<
  keyof ISponsorEquityBOL_CashReport["data"]["items"]
>[] = [
  {
    id: "transfer_proceeds",
    label: "Direct Pay Cash",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
];

export const SPONSOR_EQUITY_BOL_TAX_REPORT_TABLE_COLUMNS: ITableColumn<
  keyof ISponsorEquityBOL_TaxReport["data"]["items"]
>[] = [
  {
    id: "dates",
    label: "Date",
    minWidth: 150,
    align: "left",
  },
  {
    id: "total_taxable_income",
    label: "Total Taxable Income",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "tax_rate",
    label: "Tax Rate",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
    suffix: "%",
  },
  {
    id: "cash_taxes",
    label: "Cash Taxes",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
];

export enum OUTPUT_REPORT_PERIODICITY {
  MO = "Monthly",
  QU = "Quarterly",
  SA = "Semi-Annual",
  AN = "Annual",
}

export enum OUTPUT_REPORT_TERM {
  default = "Full-Term",
  buyout = "Buyout-Term",
  taxflip = "Flip-Term",
  ebo = "EBO",
}

export const PARTNERSHIP_COMBINED_TABLE_ROWS = [
  "deal_fmv",
  "deal_fees",
  "capital_contributions_draws",
  "cash_distributions",
  "transfer_proceeds",
  "pre_tax_cash",
  "tax_credits",
  "pre_tax_cash_with_tax_credits",
  "depreciation_benefits",
  "tax_detriments",
  "after_tax_cash",
];

export const SUMMARY_REPORT_ITEMS = {
  deal_fmv: { name: "Share of Deal FMV", suffix: "", prefix: "(-) " },
  deal_fees: { name: "Share of Deal Fees", suffix: "", prefix: "(-) " },
  capital_contributions: {
    name: "Capital Contributions",
    suffix: "",
    prefix: "(-) ",
  },
  capital_contributions_draws: {
    name: "Capital Contributions",
    suffix: "",
    prefix: "(-) ",
  },
  cash_distributions: {
    name: "Cash Distributions",
    suffix: "",
    prefix: "(+) ",
  },
  cash_paid_to_investor: {
    name: "Cash Paid to Investor",
    suffix: "",
    prefix: "",
  },
  cash_paid_to_sponsor: {
    name: "Cash Paid to Sponsor",
    suffix: "",
    prefix: "",
  },
  tax_credits: { name: "Tax Credits", suffix: "", prefix: "(+) " },
  cash_taxes: { name: "Cash Taxes", suffix: "", prefix: "(-) " },
  depreciation: { name: "Depreciation", suffix: "", prefix: "" },
  depreciation_benefits: {
    name: "Depreciation Benefits",
    suffix: "",
    prefix: "(+) ",
  },
  tax_detriments: { name: "Tax Detriments", suffix: "", prefix: "(-) " },
  pre_tax_cash: { name: "Pre-Tax Cash", suffix: "", prefix: "" },
  pre_tax_cash_with_tax_credits: {
    name: "Pre-Tax Cash (with Credits)",
    suffix: "",
    prefix: "",
  },
  after_tax_cash: { name: "After-Tax Cash", suffix: "", prefix: "" },
  tax_efficient_value: { name: "Total Value", suffix: "", prefix: "" },
  draws: { name: "Draws", suffix: "", prefix: "" },
  principal: { name: "Principal Repayments", suffix: "", prefix: "" },
  interest: { name: "Interest Payments", suffix: "", prefix: "" },
  debt_amount: { name: "Debt Amount", suffix: "", prefix: "" },
  debt_service_reserve: {
    name: "Debt Service Reserve",
    suffix: "",
    prefix: "",
  },
  debt_term: { name: "Amortization Tenor", suffix: " Yrs", prefix: "" },
  min_debt_service_coverage_ratio: {
    name: "Min Debt Service Coverage Ratio",
    suffix: "x",
    prefix: "",
  },
  avg_debt_service_coverage_ratio: {
    name: "Avg Debt Service Coverage Ratio",
    suffix: "x",
    prefix: "",
  },

  fmv_step_up: { name: "FMV Step Up", suffix: "", prefix: "(+) " },
  debt_proceeds: { name: "Term Debt Proceeds", suffix: "", prefix: "(+) " },
  debt_service: { name: "Term Debt Service", suffix: "", prefix: "(-) " },
  transfer_proceeds: { name: "Transfer Cash", suffix: "", prefix: "(+) " },
  direct_pay_proceeds: {
    name: "Direct Pay Cash",
    suffix: "",
    prefix: "(+) ",
  },
  equity: { name: "Net Equity", suffix: "", prefix: "" },
  investment_amount: { name: "Investment Amount", suffix: "", prefix: "" },
};

export const TRANSFER_SUMMARY_REPORT_ITEMS = {
  payment_total: {
    name: "Payment Total",
    suffix: "",
    is_dollar: true,
    toFixed: undefined,
  },
  payment_rate: {
    name: "Payment Rate",
    suffix: "",
    is_dollar: false,
    toFixed: 4,
  },
  discount_rate: {
    name: "Discount Rate",
    suffix: "%",
    is_dollar: false,
    toFixed: 2,
  },
  roi: { name: "ROI", suffix: "%", is_dollar: false, toFixed: 2 },
  npv_payment_total: {
    name: "NPV of Payments",
    suffix: "",
    is_dollar: true,
    toFixed: undefined,
  },
  npv_tax_credits: {
    name: "NPV of Credits",
    suffix: "",
    is_dollar: true,
    toFixed: undefined,
  },
  adjusted_payment_rate: {
    name: "Adjusted Payment Rate",
    suffix: "",
    is_dollar: false,
    toFixed: 4,
  },
  adjusted_payment_total: {
    name: "Adjusted Payment Total",
    suffix: "",
    is_dollar: true,
    toFixed: undefined,
  },
};

export const TRANSFER_SUMMARY_REPORT_NPV_ITEMS = {
  purchase: { name: "Purchase", suffix: "" },
  cash_distributions: { name: "Cash Distributions", suffix: "" },
  tax_credits: { name: "Tax Credits", suffix: "" },
  depreciation_benefits: { name: "Depreciation Benefits", suffix: "" },
  tax_detriments: { name: "Tax Detriments", suffix: "" },
  tax_efficient_value: { name: "Total Value", suffix: "" },
};

export const TAX_EQUITY_SUMMARY_IRR_ITEMS: Record<
  string,
  ITaxEquitySummaryItem
> = {
  investment_amount: { name: "Investment Amount", suffix: "", isDollar: true },
  after_tax_irr_flip_term: {
    name: "After-Tax IRR (Flip-Term)",
    suffix: "%",
    isDollar: false,
  },
  after_tax_irr: { name: "After-Tax IRR", suffix: "%", isDollar: false },
  after_tax_irr_buyout_term: {
    name: "After-Tax IRR (Buyout-Term)",
    suffix: "%",
    isDollar: false,
    visibleInCoZe: true,
  },
  roi: { name: "ROI", suffix: "%", isDollar: false },
  roi_buyout_term: {
    name: "ROI (Buyout-Term)",
    suffix: "%",
    isDollar: false,
    visibleInCoZe: true,
  },
  pre_tax_irr_flip_term: {
    name: "Pre-Tax IRR (Flip-Term)",
    suffix: "%",
    isDollar: false,
  },
  pre_tax_irr: { name: "Pre-Tax IRR", suffix: "%", isDollar: false },
  pre_tax_irr_buyout_term: {
    name: "Pre-Tax IRR (Buyout-Term)",
    suffix: "%",
    isDollar: false,
  },
  pre_tax_irr_with_tax_credit: {
    name: "Pre-Tax IRR (with Credits)",
    suffix: "%",
    isDollar: false,
  },
  pre_tax_irr_floor: {
    name: "Pre-Tax IRR (with Credits) Floor",
    suffix: "%",
    isDollar: false,
  },
  pre_tax_irr_shortfall: {
    name: "Pre-Tax IRR (with Credits) Shortfall",
    suffix: "%",
    isDollar: false,
  },
  pre_flip_cash_split_percent: {
    name: "Pre-Flip Cash Split",
    suffix: "%",
    isDollar: false,
  },
  pre_flip_tax_split_percent: {
    name: "Pre-Flip Tax Split",
    suffix: "%",
    isDollar: false,
  },
  target_flip_tenor: {
    name: "Target Flip Term",
    suffix: " Yrs",
    isDollar: false,
  },
  actual_flip_tenor: {
    name: "Actual Flip Term",
    suffix: " Yrs",
    isDollar: false,
  },
  flip_delay: { name: "Flip Delay", suffix: " Yrs", isDollar: false },
  dro_cap: { name: "DRO Cap", suffix: "%", isDollar: false },
  max_dro: { name: "Max DRO", suffix: "%", isDollar: false },
  dro_cap_exceedance: {
    name: "DRO Cap Exceedance",
    suffix: "%",
    isDollar: false,
  },
  dro_term: { name: "DRO Term", suffix: " Yrs", isDollar: false },
  preferred_return_type: {
    name: "Preferred Return Type",
    suffix: "",
    isDollar: false,
    hideWhenNull: true,
  },
  preferred_return_amount: {
    name: "Preferred Return %",
    suffix: "%",
    isDollar: false,
    hideWhenNull: true,
  },
  federal_tax_rate: { name: "Federal Tax Rate", suffix: "%", isDollar: false },
};

export const PARTNERSHIP_SUMMARY_IRR_ITEMS = {
  pre_tax_irr: { name: "Pre-Tax IRR", suffix: "%" },
  pre_tax_irr_with_tax_credit: {
    name: "Pre-Tax IRR (with Credits)",
    suffix: "%",
  },
  after_tax_irr: { name: "After-Tax IRR", suffix: "%" },
};

export const PARTNERSHIP_ALLOC_DATE_NAMES: { [key: string]: ITableColumn } = {
  // set bold to true
  row_labels: { id: "row_labels", label: "Date", minWidth: 100, align: "left" },
  tax_equity_funding_date: {
    id: "tax_equity_funding_date",
    label: "Investor Funding",
    minWidth: 100,
    align: "right",
  },
  target_flip_date: {
    id: "target_flip_date",
    label: "Target Flip",
    minWidth: 100,
    align: "right",
  },
  actual_flip_date: {
    id: "actual_flip_date",
    label: "Actual Flip",
    minWidth: 100,
    align: "right",
  },
  tax_step_down_date: {
    id: "tax_step_down_date",
    label: "Tax Step Down",
    minWidth: 100,
    align: "right",
  },
  tax_compliance_period_date: {
    id: "tax_compliance_period_date",
    label: "Tax Compliance Period",
    minWidth: 100,
    align: "right",
  },
  deal_end_date: {
    id: "deal_end_date",
    label: "Deal End",
    minWidth: 100,
    align: "right",
  },
};

export const PARTNERSHIP_ALLOC_ITEM_NAMES = {
  starting_on: "Starting On",
  ending_on: "Ending On",
  te_cash_distributions: "TE Cash Distributions",
  se_cash_distributions: "SE Cash Distributions",
  te_tax_allocations: "TE Tax Allocations",
  se_tax_allocations: "SE Tax Allocations",
  te_tax_credit_allocations: "TE Tax Credit Allocations",
  se_tax_credit_allocations: "SE Tax Credit Allocations",
  te_transfer_cash: "TE Transfer Cash",
  se_transfer_cash: "SE Transfer Cash",
  te_preferred_return_type: "TE Preferred Return Type",
  te_preferred_return: "TE Preferred Return",
  se_return_of_capital: "SE Return of Capital",
};

export const DEBT_SUMMARY_IRR_ITEMS = {
  // all_in_interest_rate: { name: "All-In Interest Rate", suffix: "%" },
  debt_amount: { name: "Debt Amount", suffix: "", prefix: "", precision: 0 },
  principal: {
    name: "Principal Repayments",
    suffix: "",
    prefix: "",
    precision: 0,
  },
  interest: { name: "Interest Payments", suffix: "", prefix: "", precision: 0 },
  base_rate: { name: "Base Rate", suffix: "%", precision: 3 },
  spread: { name: "Spread", suffix: "%", precision: 3 },
  spread_step_up: { name: "Step Up", suffix: "%", precision: 3 },
  spread_step_up_frequency: {
    name: "Step Up Frequency (Yrs)",
    suffix: " Yrs",
    precision: 2,
  },
  debt_irr: { name: "Debt IRR", suffix: "%", precision: 3 },
  wal: { name: "WAL", suffix: " Yrs", precision: 2 },
  duration: { name: "Duration", suffix: " Yrs", precision: 2 },
};

export const SPONSOR_EQUITY_BOL_SUMMARY_ITEMS: Record<
  SponsorEquityBOL_ReportSummaryMetricsForView,
  {
    name: string;
    format?: boolean;
    suffix?: string;
    prefix?: string;
    enum?: { [k: string]: string };
  }
> = {
  net_equity: { name: "Investment Amount", format: true },
  pre_tax_irr: {
    name: "Pre-Tax IRR",
    suffix: "%",
  },
  after_tax_irr: {
    name: "After-Tax IRR",
    suffix: "%",
  },
  pre_tax_irr_buyout_term: {
    name: "Pre-Tax IRR (Buyout-Term)",
    suffix: "%",
  },
  after_tax_irr_buyout_term: {
    name: "After-Tax IRR (Buyout-Term)",
    suffix: "%",
  },
  carryforward_credits_and_losses: {
    name: "Carryforward Credits and Losses",
    enum: CARRYFORWARD_CREDITS,
  },
  pre_tax_moic: {
    name: "Pre-Tax MOIC",
    suffix: "x",
  },
  after_tax_moic: {
    name: "After-Tax MOIC",
    suffix: "x",
  },
  pre_tax_moic_buyout_term: {
    name: "Pre-Tax MOIC (Buyout-Term)",
    suffix: "x",
  },
  after_tax_moic_buyout_term: {
    name: "After-Tax MOIC (Buyout-Term)",
    suffix: "x",
  },
  pre_tax_cash_yield_5_yr: {
    name: "Pre-Tax Cash Yield (5-Yr)",
    suffix: "%",
  },
  pre_tax_cash_yield_10_yr: {
    name: "Pre-Tax Cash Yield (10-Yr)",
    suffix: "%",
  },
  after_tax_cash_yield_5_yr: {
    name: "After-Tax Cash Yield (5-Yr)",
    suffix: "%",
  },
  after_tax_cash_yield_10_yr: {
    name: "After-Tax Cash Yield (10-Yr)",
    suffix: "%",
  },
  pre_tax_payback_period: {
    name: "Pre-Tax Payback Period",
    suffix: " Yrs",
  },
  after_tax_payback_period: {
    name: "After-Tax Payback Period",
    suffix: " Yrs",
  },
  pre_tax_discount_rate: {
    name: "Pre-Tax Discount Rate",
    suffix: "%",
  },
  after_tax_discount_rate: {
    name: "After-Tax Discount Rate",
    suffix: "%",
  },
  pre_tax_npv: { name: "Pre-Tax NPV", format: true },
  after_tax_npv: { name: "After-Tax NPV", format: true },
  pre_tax_npv_buyout_term: { name: "Pre-Tax NPV (Buyout-Term)", format: true },
  after_tax_npv_buyout_term: {
    name: "After-Tax NPV (Buyout-Term)",
    format: true,
  },
};

export const DEVELOPER_REPORT_SUMMARY_ITEMS: Record<
  keyof IDeveloperReportSummary["data"]["summary"],
  { name: string; suffix?: string; prefix?: string }
> = {
  pre_tax_irr: { name: "Pre-Tax IRR", suffix: "%" },
  after_tax_irr: { name: "After-Tax IRR", suffix: "%" },
  pre_tax_moic: { name: "Pre-Tax MOIC", suffix: "x" },
  after_tax_moic: { name: "After-Tax MOIC", suffix: "x" },
  developer_fee_payment: { name: "Developer Fee Payment", prefix: "$" },
  developer_fee_payment_per_watt_dc: {
    name: "Developer Fee Payment $/W",
    prefix: "$",
  },
  // pre_tax_npv: { name: "Pre-Tax NPV" },
  // after_tax_npv: { name: "After Tax NPV" },
};

export const DEVELOPER_REPORT_CASHFLOW_ITEMS: Record<
  keyof Omit<
    IDeveloperReportSummary["data"]["cashflow"],
    "equity_contributions" | "project_cost"
  >,
  { name: string; bold?: boolean; suffix?: string; prefix?: string }
> = {
  development_cost: { name: "(-) Development Cost" },
  credit_support_cash_collateral: {
    name: "(-) Credit Support Cash Collateral",
  },
  credit_support_lc_fee: { name: "(-) Credit Support LC Fee" },
  total_cash_outflows: { name: "Total Cash Outflows", bold: true },
  development_cost_reimbursements: {
    name: "(+) Development Cost Reimbursements",
  },
  developer_contributed_basis: { name: "(+) Developer Contributed Basis" },
  credit_support_reimbursements: { name: "(+) Credit Support Reimbursements" },
  developer_fee_payments: { name: "(+) Developer Fee Payments" },
  total_cash_inflows: { name: "Total Cash Inflows", bold: true },
  net_cashflow: { name: "Net Cashflow", bold: true },
  pre_tax_cash: { name: "Pre-Tax Cash" },
  cash_taxes: { name: "Cash Taxes" },
  after_tax_cash: { name: "After Tax Cash" },
};

export const TRANSFER_SUMMARY_IRR_ITEMS = {
  payment_rate: { name: "Payment Rate", suffix: "" },
  adjusted_payment_rate: {
    name: "Adjusted Payment Rate (NPV of Credits / NPV of Payments)",
    suffix: "",
  },
  roi: { name: "ROI", suffix: "%" },
};

export const SPONSOR_EQUITY_SUMMARY_IRR_ITEMS: Record<
  string,
  ISponsorEquitySummaryItem
> = {
  net_equity: {
    name: "Investment Amount",
    suffix: "",
    isNumberFormatted: true,
  },
  pre_tax_irr: {
    name: "Pre-Tax IRR",
    suffix: "%",
  },
  after_tax_irr_carryforward: {
    name: "After-Tax IRR",
    suffix: "%",
  },
  pre_tax_irr_buyout_term: {
    name: "Pre-Tax IRR (Buyout-Term)",
    suffix: "%",
  },
  after_tax_irr_buyout_term: {
    name: "After-Tax IRR (Buyout-Term)",
    suffix: "%",
  },
  pre_tax_cash_yield_5_yr: {
    name: "Pre-Tax Cash Yield (5-Yr)",
    suffix: "%",
  },
  after_tax_cash_yield_5_yr: {
    name: "After-Tax Cash Yield (5-Yr)",
    suffix: "%",
  },
  pre_tax_cash_yield_10_yr: {
    name: "Pre-Tax Cash Yield (10-Yr)",
    suffix: "%",
  },
  after_tax_cash_yield_10_yr: {
    name: "After-Tax Cash Yield (10-Yr)",
    suffix: "%",
  },
  pre_tax_moic: {
    name: "Pre-Tax MOIC",
    suffix: "x",
  },
  after_tax_moic: {
    name: "After-Tax MOIC",
    suffix: "x",
  },
  pre_tax_moic_buyout_term: {
    name: "Pre-Tax MOIC (Buyout-Term)",
    suffix: "x",
  },
  after_tax_moic_buyout_term: {
    name: "After-Tax MOIC (Buyout-Term)",
    suffix: "x",
  },
  pre_tax_payback_period: {
    name: "Pre-Tax Payback Period",
    suffix: " Yrs",
  },
  after_tax_payback_period: {
    name: "After-Tax Payback Period",
    suffix: " Yrs",
  },
  pre_tax_discount_rate: {
    name: "Pre-Tax Discount Rate",
    suffix: "%",
  },
  after_tax_discount_rate: {
    name: "After-Tax Discount Rate",
    suffix: "%",
  },
  pre_tax_npv: {
    name: "Pre-Tax NPV",
    suffix: "",
    isNumberFormatted: true,
  },
  after_tax_npv: {
    name: "After-Tax NPV",
    suffix: "",
    isNumberFormatted: true,
  },
  pre_tax_npv_buyout_term: {
    name: "Pre-Tax NPV (Buyout-Term)",
    suffix: "",
    isNumberFormatted: true,
  },
  after_tax_npv_buyout_term: {
    name: "After-Tax NPV (Buyout-Term)",
    suffix: "",
    isNumberFormatted: true,
  },
  carryforward_credits_and_losses: {
    name: "Carryforward Credits and Losses",
    suffix: "",
    enumType: CARRYFORWARD_CREDITS,
  },
  federal_tax_rate: {
    name: "Federal Tax Rate",
    suffix: "%",
  },
  new_federal_tax_rate: {
    name: "New Federal Tax Rate",
    suffix: "%",
  },
  federal_tax_rate_change_year: {
    name: "Tax Rate Change Year",
    suffix: "",
    isString: true,
  },
  state_tax_rate: {
    name: "State Tax Rate",
    suffix: "%",
  },
  composite_tax_rate: {
    name: "Composite Tax Rate",
    suffix: "%",
  },
  new_composite_tax_rate: {
    name: "New Composite Tax Rate",
    suffix: "%",
  },
};

export const OUTPUT_REPORT_PERIODICITY_OPTIONS = enumToOptions(
  OUTPUT_REPORT_PERIODICITY,
);

export const OUTPUT_REPORT_TERM_OPTIONS = enumToOptions(OUTPUT_REPORT_TERM);

export const IGNORE_SPONSOR_EQUITY_IRR_TABLE_FIELDS_FOR_EO = [
  "Pre-Tax IRR (Buyout-Term)",
  "After-Tax IRR (Buyout-Term)",
  "Pre-Tax MOIC (Buyout-Term)",
  "After-Tax MOIC (Buyout-Term)",
  "Pre-Tax NPV (Buyout-Term)",
  "After-Tax NPV (Buyout-Term)",
];

export const IGNORE_SPONSOR_EQUITY_TABLE_FIELDS_FOR_DP = ["(+) Transfer Cash"];

export const IGNORE_SPONSOR_EQUITY_TABLE_FIELDS_FOR_TRANSFER = [
  "(+) Direct Pay Cash",
];

export const CONSTRUCTION_DEBT_CASH_REPORT_COLUMNS: ITableColumn<
  keyof IConstructionDebtCashReport["data"]["items"]
>[] = [
  {
    id: "dates",
    label: "Date",
    minWidth: 150,
    align: "left",
  },
  {
    id: "total_project_costs",
    label: "Total Project Cost",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "total_idc",
    label: "Interest During Construction",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "total_commitment_fees",
    label: "Commitment Fees",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "total_upfront_fees",
    label: "Upfront Fees",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "total_lc_fees",
    label: "LC Fees",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "total_deal_allocated_cost",
    label: "Deal Allocated Costs",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "total_project_costs_including_idc_and_fees",
    label: "Deal Project Cost",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "equity_funding",
    label: "Equity Contributions",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "excess_equity",
    label: "Excess Equity",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "construction_loan_drawdowns",
    label: "Construction Loan Draw Down",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "tax_equity_bridge_loan_drawdowns",
    label: "Investor Bridge Loan Draw Down",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  // {
  //   id: "transfer_bridge_loan_drawdowns",
  //   label: "Transfer Bridge Loan Draw Down",
  //   minWidth: 150,
  //   align: "right",
  //   formatNumbers: true,
  // },
  {
    id: "total_sources",
    label: "Total Sources",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
];

export const CONSTRUCTION_DEBT_EQUITY_REPORT_COLUMNS: ITableColumn<
  keyof IConstructionDebtEquityReport["data"]["items"] | ""
>[] = [
  {
    id: "dates",
    label: "Date",
    minWidth: 150,
    align: "left",
  },
  {
    id: "beginning_balance",
    label: "Beginning Balance",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
    totalVisible: false,
  },
  {
    id: "funding",
    label: "Funding",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "drawdowns",
    label: "Draw Down",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "ending_balance",
    label: "Ending Balance",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
    totalVisible: false,
  },
  // {
  //   id: "excess_equity_funding",
  //   label: "Excess Equity Funding",
  //   minWidth: 150,
  //   align: "right",
  //   formatNumbers: true,
  // },
  // {
  //   id: "excess_equity_beginning_balance",
  //   label: "Excess Equity Beginning Balance",
  //   minWidth: 150,
  //   align: "right",
  //   formatNumbers: true,
  //   totalVisible: false,
  // },
  {
    id: "excess_equity_funded",
    label: "Excess Equity Funded",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "excess_equity_released_funded_by_debt",
    label: "Excess Equity Released / Funded by Debt",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  // {
  //   id: "excess_equity_ending_balance",
  //   label: "Excess Equity Ending Balance",
  //   minWidth: 150,
  //   align: "right",
  //   formatNumbers: true,
  //   totalVisible: false,
  // },
];

export const CONSTRUCTION_DEBT_LOAN_REPORT_COLUMNS: ITableColumn<
  keyof IConstructionDebtLoanReport["data"]["items"]
>[] = [
  {
    id: "dates",
    label: "Date",
    minWidth: 150,
    align: "left",
  },
  {
    id: "beginning_balance",
    label: "Beginning Balance",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
    totalVisible: false,
  },
  {
    id: "drawdowns",
    label: "Draw Down",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "repayment",
    label: "Principal Repayments",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "ending_balance",
    label: "Ending Balance",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
    totalVisible: false,
  },
  {
    id: "unfunded_debt",
    label: "Unfunded Commitment",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
    totalVisible: false,
  },
  {
    id: "interest_payments",
    label: "Interest",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "commitment_fees",
    label: "Commitment Fees",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "upfront_fees",
    label: "Upfront Fees",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
];

export const DEBT_COVERAGE_REPORT_TABLE_COLUMNS: ITableColumn[] = [
  {
    id: "dates",
    label: "Date",
    minWidth: 150,
    align: "left",
  },
  {
    id: "cf",
    label: "Cash Flow (CF)",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "cf_adjusted",
    label: "CF (Periodicity Adjusted)",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  // {
  //   id: "cfads",
  //   label: "CFADS",
  //   minWidth: 120,
  //   align: "right",
  //   formatNumbers: true,
  // },
  {
    id: "cfads_adjusted",
    label: "CFADS (Periodicity Adjusted)",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "debt_service",
    label: "Debt Service",
    minWidth: 150,
    align: "right",
    formatNumbers: true,
  },
  {
    id: "coverage_ratio",
    label: "Coverage Ratio",
    minWidth: 150,
    align: "right",
    formatNumbers: false,
  },
];
